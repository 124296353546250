import styles from "../index.module.scss";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import newGif from "@images/new.gif";
import { useRouter } from "next/router";
import { useNormalMenu } from "./menu";
import buriedsdk from "@/utils/zz-buried/index";
const NormalMenu = () => {
  const { t } = useTranslation(['nav']);
  const router = useRouter();
  const { locales, locale: activeLocale } = router;
  let normalMenu: any = useNormalMenu(activeLocale);
  return normalMenu.map((item) => (
    <li
      key={item.pathname}
      className={`${styles["normal-menu-item"]} relative `}
    >
      <a
        className={`${styles["item-title"]}`}
        href={item.href}
        rel="nofollow"
        onClick={() => {
          buriedsdk.buried({
            event: `bastaccount官网_Web`,
            scene: `菜单_${item.name}`,
            URL: process.env.publicApiDomain,
          });
        }}
      >
        {t(item.name)}
      </a>
      {item.children && item.children.length > 0 ? (
        <>
          <i
            className={`iconfont icon-a-10xiala1 ml-[4px] ${styles.normalRotate}`}
          ></i>
          <div
            className={`${styles["children-box"]} absolute bg-[#fff] rounded-[8px]`}
          >
            {item.children.map((child) => (
              <a
                href={child.href}
                key={child.name}
                className={`${styles["child-item"]}`}
                onClick={() => {
                  buriedsdk.buried({
                    event: `bastaccount官网_Web`,
                    scene: `菜单_${item.name}_${child.name}`,
                    URL: process.env.publicApiDomain,
                  });
                }}
              >
                <span>{t(child.name)}</span>
              </a>
            ))}
          </div>
        </>
      ) : null}
    </li>
  ));
};

export default NormalMenu;
