import styles from "./Questionnaire.module.scss";
import { useState, useEffect } from "react";
import { Modal, Radio, Input } from "antd";
import { getQuestionnaire, submitQuestionnaire } from "@/service";
import { getToken } from "@/utils/auth";

const { TextArea } = Input;

const Index = (props: any) => {
  let [isModalOpen, setIsModalOpen] = useState(false);
  let [step, setStep] = useState(1);
  let [answer, setAnswer] = useState("");
  let [success, setSuccess] = useState(false);
  let [questionnaireId, setQuestionnaireId] = useState<number | string>("");
  let [answerList, setAnswerList] = useState<any>([]);
  let [questionList, setQuestionList] = useState<object[]>([]);
  let [optionDesc, setOptionDesc] = useState("");
  let [currentQuestion, setCurrentQuestion] = useState<any>({})
  let [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    if (document.documentElement.clientWidth < 1024 && getToken()) {
      setTimeout(() => {
        initQuestions();
      }, 1000)
    }
  }, [])

  useEffect(() => {
    if (questionList.length !== 0) {
      setCurrentQuestion(questionList[step - 1] ?? {
        optionList: [],
        desc: ""
      })
      setIsModalOpen(true);
    }
  }, [questionList, step])
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  function nextQuestion() {
    if (submitLoading || !answer || (currentQuestion.optionList[currentQuestion.optionList.length - 1]?.option === answer && optionDesc === '')) {
      return;
    }
    const value = answer;
    const desc = optionDesc;
    // 构建答案数据
    answerList.push({
      option: value,
      option_desc: desc,
      question_id: currentQuestion.questionId
    });
    // 判断答完则提交
    if (step === questionList.length) {
      submitAnswer();
      return;
    }
    const _step = step + 1
    setStep(_step);
    setAnswer("");
    setOptionDesc("")
  }
  function onChange(e) {
    setAnswer(e.target.value);
  }
  function skipQuestion() {
    setIsModalOpen(false)
  }
  async function initQuestions() {
    const res = await getQuestionnaire();
    if (res && res?.code === 0 && res.data?.questionnaireId) {
      setQuestionList([...res.data.questionList])
      setQuestionnaireId(res.data?.questionnaireId)
    }
  }
  async function submitAnswer() {
    setSubmitLoading(true)
    const res = await submitQuestionnaire({
      questionnaire_id: questionnaireId,
      answer_list: answerList
    });
    if (res && res?.code === 0) {
      setSuccess(true)
    }
    setSubmitLoading(false)
  }

  function changeDesc(e) {
    const val = e.target.value
    setOptionDesc(val)
    if (val) {
      const length = currentQuestion.optionList.length;
      setAnswer(currentQuestion.optionList[length - 1]?.option);
    }
  }

  const modalRender = () => {
    return <div  onClick={(e) => {
      e.stopPropagation();
    }}>
      {
        success ? (
          <div className={`${styles['questionnaire-wrapper']} flex flex-col items-center justify-center !px-[8px]`}>
            <img className={styles['questionnaire-image_success']}
                   src="https://mcdn.bastaccount.net/custom/images/question-icon.png" />
            <p className={`text-center ${styles['questionnaire-successText']}`}>
              Thank you for completing <br />the questionnaire
            </p>
            <p className={styles['questionnaire-button']}
               style={{marginTop: "42px"}}
               onClick={skipQuestion}>
              Complete
            </p>
          </div>
        ) : (
          <div className={styles['questionnaire-wrapper']} >
            <img className={styles["questionnaire-image"]}
                   src="https://mcdn.bastaccount.net/custom/images/question-icon-empty.png" />
            <div className={styles["questionnaire-progress"]}>
              <p style={{transform: "skew(25deg)"}}>Question { step }/{ questionList.length }</p>
            </div>
            <div
              className={styles["questionnaire-title"]}
            >
              { currentQuestion.desc }
            </div>
            <div className={styles['questionnaire-optionWrap-fixed']}>
              <div className={styles["questionnaire-optionWrap"]}>
                {
                  <Radio.Group onChange={onChange} value={answer}>
                    {
                      currentQuestion.optionList.map((item) => {
                        return <Radio value={item.option} key={item.option}>{ item.desc }</Radio>
                      })
                    }
                  </Radio.Group>
                }
                {
                  <TextArea maxLength={500}
                            disabled={answer !== currentQuestion.optionList[currentQuestion.optionList.length - 1]?.option}
                            rows={4}
                            value={optionDesc}
                            onChange={changeDesc}
                            className="!mb-[30px]" />
                }
              </div>
            </div>
            <div className={styles["questionnaire-btnWrap"]}>
              <p
                onClick={nextQuestion}
                className={`${styles['questionnaire-button']} ${!answer || (currentQuestion.optionList[currentQuestion.optionList.length - 1]?.option === answer && optionDesc === '')
                  ? styles['disabled'] : ''}`}
              >
                { step < questionList.length ? "Next" : "Submit" }
              </p>
              {
                step === 1 ? (
                  <p
                    className={styles["questionnaire-skip"]}
                    onClick={skipQuestion}
                  >
                    Skip
                  </p>
                ) : ""
              }
            </div>
          </div>
        )
      }
    </div>
  }


  return <div>
    <Modal title=""
           visible={isModalOpen}
           onCancel={handleCancel}
           footer={null}
           closable={false}
           modalRender={modalRender}
           maskClosable={false}
           centered
           wrapClassName="QuestionModal"
           width={316}>
    </Modal>
  </div>
}

export default Index
