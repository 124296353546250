/*
 * @Date: 2022-09-05 13:58:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-03 15:02:48
 * @FilePath: /bastaccount-global-website-node/watch/index.js
 */
import chartInit from '@/watch/chart.js'
import chartMiInit from '@/watch/chartMi.js'

import chartPieInit from '@/watch/chartPie.js'
import { convertToI18, formatDigit } from "@/utils/count";

export const  antiMate =(targetBoxs, child, ant='cm-in') =>{
    var inter = targetBoxs.find(child);
    var antFn = function (target, length, cur) {
        if (cur <= length) {
            if (cur == 1) {
                $(target[cur - 1]).addClass(ant)
                antFn(target, length, cur + 1)
            } else {
                setTimeout(function () {
                    $(target[cur - 1]).addClass(ant)
                    antFn(target, length, cur + 1)
                }, 200);
            }
        }

    }
    antFn(inter, targetBoxs.find(child).length, 1)
}

export const watchDom = (data) => {
  
  const timmer  = setInterval(()=> {
    //&&  window.$ && window.CountUp && window.G2
    // const isPclength = window.innerWidth > 1024 ? 16: 5  // && document.querySelectorAll(".animate-box").length >= isPclength// 配置好动画数量
    if (['interactive', 'complete'].includes(document.readyState) && document.querySelectorAll(".animate-box") ) {
        var boxs = document.querySelectorAll(".animate-box");
        var observer = new IntersectionObserver(function (entries) {
          entries.forEach(function (item) {
              const intersectionRatio = item.intersectionRatio
              if ($(item.target).hasClass('cm-in-img-ant')) {
                  if (item.isIntersecting && !$(item.target).hasClass('cm-in-img')) {
                      $(item.target).addClass('cm-in-img')
                  }
              } else if ($(item.target).hasClass('cm-in-wait')) {
                  if (item.isIntersecting && !$(item.target).hasClass('cm-y')) {
                      $(item.target).addClass('cm-y')
                      antiMate($(item.target), '.ant-y', 'cm-y')
                  }
              } else if ($(item.target).hasClass('cm-in-chart')) {
                if (item.isIntersecting && !$(item.target).hasClass('cm-in')) {
                    $(item.target).addClass('cmm-in')
                    console.log('typeof',typeof data.trendChart)
                    if (data.trendChart) {
                        if(!$(item.target).hasClass('cm-in-chart-init')) {
                            if (window.innerWidth < 1080) {
                                chartMiInit(item.target, data)
                            } else {
                                chartInit(item.target, data)

                            }
                        
                        $(item.target).addClass('cm-in-chart-init')
                    }
                    }
                   

                }
            } else if ($(item.target).hasClass('cm-in-chart-pie')) {
                if (item.isIntersecting && !$(item.target).hasClass('cm-in')) {
                    $(item.target).addClass('cmm-in')
                
                    if(!$(item.target).hasClass('cm-in-chart-init')) {
                            chartPieInit(item.target)
                        
                        $(item.target).addClass('cm-in-chart-init')
                    }

                }
            } else if ($(item.target).hasClass('cm-in-number')) {
        
                if (item.isIntersecting && !$(item.target).hasClass('cm-in')) {
                    $(item.target).addClass('cmm-in')
                    if (data.trendData) {
                        if(!$(item.target).hasClass('cm-in-number-init')) {
                            var options = {
                                useEasing: true,
                                useGrouping: true,
                                separator: '',
                                decimal: '.',
                                prefix: '',
                                suffix: ''
                              };
                              console.log(data.trendData)
                              let salesCount = data.trendData.totalSales
                              
                            new CountUp("js1-bounty", 0, data.trendData.gmv, 0, 2, {...options, separator: ',', prefix: '$'}).start();
                            new CountUp("js2-bounty", 0, salesCount, 0, 1, {...options, separator: ',', prefix: ''}).start();
                            new CountUp("js3-bounty", 0, data.trendData.productCount, 0, 1, {...options, separator: ',', prefix: ''}).start();
                            new CountUp("js4-bounty", 0, data.trendData.totalPlayCountIncr, 0, 1, {...options, separator: ','}).start();
                      
                            $(item.target).addClass('cm-in-number-init')
                        }
                    }
                   

                }
            } else {
                  if (item.isIntersecting && !$(item.target).hasClass('cmm-in')) {
                      $(item.target).addClass('cmm-in')
                      antiMate($(item.target), '.ant')
                  }

              }
          });
      });
      for (var i = 0; i < boxs.length; i++) {
          observer.observe(boxs[i]);
      }
    //   $("a[data*=#m]").bind("click touch", function () {
    //     $('html,body').animate(
    //         { scrollTop: ($($(this).attr('data')).offset().top) },
    //         { duration: 500, easing: "swing" });
    //     return false
    // });

      clearInterval(timmer)


    }
  }, 600)
  return timmer
}
