import React, { useMemo, useState } from 'react'
import styles from './gift.module.scss'
import { getActivityVip } from '@/service'
import buriedSdk from '@/utils/zz-buried/index'
import { getStorageInfo } from '@/utils/auth'
import { getDeviceId } from '@/utils/auth'

export default function GiftDialog({ receiveDiscountData, onClose, onSuc, onError }) {
  const backgroundImage = useMemo(() => {
    const version = receiveDiscountData?.comboGrade === 1 ? 'Basic' : 'Pre'
    return `https://mcdn.bastaccount.net/custom/images/activity/receiveActivity/en/h5${version}_3.png`
  }, [receiveDiscountData])

  const handleClose = (e) => {
    e.stopPropagation()
    onClose?.()
  }

  const handleReceive = (e) => {
    e.stopPropagation()
    const id = receiveDiscountData?.marketingId

    buriedSdk?.buried({
      event: '按钮点击',
      scene: `领取会员按钮-领取次数统计`,
      page_name: '首页',
      page_url: document?.URL,
      user_id: getStorageInfo()?.userId,
      json_str: JSON.stringify({
        marketing_id: id
      }),
      URL: process.env.NEXT_PUBLIC_USER_API_DOMAIN
    })
    getActivityVip(14, id, getDeviceId())
      .then((res) => {
        console.log('getActivityVip~~  ', res)
        if (res.code === 0) {
          onSuc?.()
        } else {
          onError?.(res?.msg)
        }
      })
      .catch((e) => {
        onError?.(e?.msg)
      })
  }
  return (
    <div className={`${styles['gift-dialog']}`}>
      <div
        className={`${styles['container']}`}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div
          className={`${styles['btn']} flex items-center justify-center`}
          onClick={handleReceive}
        >
          CLAIM IT NOW
        </div>
        <div
          className={`${styles['close']} flex items-center justify-center`}
          onClick={handleClose}
        >
          <i className="iconfont icon-a-55guanbi-da" />
        </div>
      </div>
    </div>
  )
}
