/*
 * @Descripttion:
 * @version:
 * @Author: ared
 * @Date: 2022-10-27 14:46:04
 * @LastEditors: M.re undefined
 * @LastEditTime: 2024-02-20 18:32:23
 */
import styles from "../index.module.scss";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import newGif from "@images/new.gif";
import { useRouter } from "next/router";
import { usePcMenu, usePcSeoMenu } from "./menu";
import faceBook from "@images/home/facebookfill@2x.png";
import whatsapp from "@images/home/whatsapp@2x.png";
import siyu from "@images/home/siyu@2x.png";
import Zalo from "@images/home/Zalo@2x.png";
import SiyuActive from "./siyuActive";
import NormalMenu from "./NormalMenu";
import { getLangLink } from "@/utils/lang";
import buriedsdk from "@/utils/zz-buried/index";
import right from "@images/features/right.png";
const MenuList = (props: any) => {
  const { t } = useTranslation("nav");
  const router = useRouter();
  const { locales, locale: activeLocale } = router;
  const open = () => {
    props.navRef.current?.classList.add("nav-hover");
  };
  const close = () => {
    props.navRef.current?.classList.remove("nav-hover");
  };
  const menu = usePcSeoMenu();
  const isCurentPath = (el) => {
    return "";
    // console.log(router.pathname);
    // if (el.path === "/") {
    //     if (router.pathname === "/") {
    //         return " !text-[#FF3E5F]";
    //     }
    // } else {
    //     return router.pathname.includes(el.path) ? " !text-[#FF3E5F]" : "";
    // }
  };

  const handleClick = (type: String) => {
    // buriedsdk.buried({
    //     scene: "私域引导" + "-" + type,
    //     page_name: route.path,
    //     event: "按钮点击",
    // });
    if (type === "Facebook") {
      window.open("https://m.me/101829126144736", "_blank");
    }
    if (type === "WhatsApp") {
      window.open("https://wa.me/message/KVRCOSIU33QUA1", "_blank");
    }
    if (type === "Zalo") {
      // showZalo.value = true;
    }
  };
  // {
  //     name: "Pricing",
  //     icon: "icon-boke",
  //     path: "checkout",
  //     href: pcUrl + `/checkout`,
  // },
  // {
  //     name: "blogs",
  //     icon: "icon-boke",
  //     href: `/blogs`,
  //     path: "blogs",
  // },
  // {
  //     name: "Reports",
  //     icon: "icon-boke",
  //     href: "/tiktok-reports",
  //     path: "reports"
  // }
  return (
    <div className="h-full">
      <ul className={styles.menus}>
        {/* 一级 */}
        {menu.map((el: any) => {
          return (
            <li
              key={el.name}
              className={`h-full flex items-center ${styles.menusItem} `}
              onMouseEnter={() => open()}
              onMouseLeave={() => close()}
            >
              <a href={el.href} className={"relative"} rel="nofollow">
                {el.isNew ? (
                  <div className="absolute -right-[28px] -top-[14px]">
                    <Image
                      lazyBoundary="10px"
                      src={newGif}
                      width={36}
                      height={18}
                      alt=""
                    ></Image>
                  </div>
                ) : null}
                <span className={isCurentPath(el)}>{t(el.name)}</span>
                {el.children && el.children.length && (
                  <i
                    className={
                      `iconfont icon-a-10xiala1 ml-[4px] ${styles.iconArrow} ` +
                      isCurentPath(el)
                    }
                  ></i>
                )}
              </a>

              {/* 二级 */}
              {el.children && el.children.length ? (
                <div className={styles.hoverMenu}>
                  <div className="defeaultContainer p-[20px]">
                    <div className="w-full grid grid-cols-3 gap-x-[73px] gap-y-[24px]">
                      {el.children.map((item: any) => {
                        return (
                          <a
                            href={item.href}
                            rel="nofollow"
                            key={item.name}
                            onClick={() => {
                              buriedsdk.buried({
                                event: `按钮点击`,
                                scene: `官网顶部导航栏-${item.name}落地页`,
                                URL: process.env.publicApiDomain,
                              });
                            }}
                            className="!h-[80px] flex items-center px-[14px] rounded-[15px] border-[1px] border-[#F0F0F0] box-border hover:bg-[#F4F4F7]"
                          >
                            <div className="w-[38px] !h-[38px] rounded-[9px] bg-[#F8F8F8] flex items-center justify-center">
                              <Image
                                width={20}
                                height={20}
                                src={item.icon}
                                alt={item.name}
                              ></Image>
                            </div>
                            <div className="flex-1 ml-[16px] flex flex-col justify-center">
                              <h3 className="mb-[7px] text-[18px] font-bold text-[#2E384E] leading-[23px] cursor-pointer">
                                {t(item.name)}
                              </h3>
                              <p className="mb-0 text-[12px] text-[#2E384E] leading-[14px] cursor-pointer">
                                {t(item.desc)}
                              </p>
                            </div>
                            <Image
                              width={16}
                              height={16}
                              src={right}
                              alt={item.name}
                            ></Image>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
              {/* {el.children && el.children.length ? (
                <div className={styles.hoverMenu}>
                  <div className="defeaultContainer p-[20px]">
                    <div className="flex w-full justify-between">
                      {el.children.map((item: any) => {
                        return (
                          <div className="flex-1" key={item.name}>
                            <span
                              className={"flex items-center  " + styles.onecate}
                            >
                              {t(item.name)}
                            </span>
                            {item.children && item.children.length ? (
                              <div>
                                {item.children.map((twocate) => {
                                  return (
                                    <a
                                      key={twocate.name}
                                      href={twocate.href}
                                      rel="nofollow"
                                      className={styles.twocate}
                                      onClick={() => {
                                        buriedsdk.buried({
                                          event: `bastaccount官网_Web`,
                                          scene: `菜单_${el.name}_${item.name}_${twocate.name}`,
                                          URL: process.env.publicApiDomain,
                                        });
                                      }}
                                    >
                                      {t(twocate.name)}
                                    </a>
                                  );
                                })}
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null} */}
            </li>
          );
        })}
        {/* 常规菜单 */}
        <NormalMenu></NormalMenu>
        {/* 联盟推广落地页 */}
        {/* <li className={`h-full flex items-center ${styles.menusItem} `}>
          <a
            href={getLangLink(`/affiliate-program`, activeLocale)}
            className={"relative"}
            rel="nofollow"
            onClick={() => {
              buriedsdk.buried({
                event: `bastaccount官网_Web`,
                scene: `首页官网-顶部导航栏-Affiliate Program`,
                URL: process.env.publicApiDomain,
              });
            }}
          >
            {t("Affiliate Program")}
          </a>
        </li> */}
        {/* <li className={`h-full flex items-center ${styles.menusItem} `}>
          <a
            href={getLangLink(`/about-us`, activeLocale)}
            className={"relative"}
            rel="nofollow"
            onClick={() => {
              buriedsdk.buried({
                event: `bastaccount官网_Web`,
                scene: `菜单_About Us`,
                URL: process.env.publicApiDomain,
              });
            }}
          >
            {t("About Us")}
          </a>
        </li> */}
        {/* 私域引导 */}
        {/* <SiyuActive></SiyuActive> */}
      </ul>
    </div>
  );
};

export default MenuList;
