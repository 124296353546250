import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import styles from './gift.module.scss'
import GiftDialog from './GiftDialog'
import Toast from './Toast'
import { getPayDiscountInfo } from '@/service'
import { getStorageInfo } from '@/utils/auth'
import dayjs from 'dayjs'
import buriedSdk from '@/utils/zz-buried/index'
import { getDeviceId } from '@/utils/auth'

const Gift = (props) => {
  const { isHome } = props

  const [giftVisible, setGiftVisible] = useState(false)
  const [tipVisible, setTipVisible] = useState(false) //提示语
  const [dlologVisible, setDlologVisible] = useState(false) // 领取弹框

  const [errMsg, setErrMsg] = useState('')

  const [receiveDiscountData, setReceiveDiscountData] = useState<any>({})

  const caKey = `receive-discount-visible-${getStorageInfo()?.userId}` // 该用户今日是否已经弹出
  const nowDate = dayjs(new Date()).format('YYYY-MM-DD')

  const getTipVisible = () => {
    setTipVisible(true)

    setTimeout(() => {
      setTipVisible(false)
    }, 3000)
  }

  const getPayDiscountData = () => {
    getPayDiscountInfo(14, 7, getDeviceId()).then((res) => {
      if (res.code === 0) {
        if (res?.data && res?.data?.length) {
          setGiftVisible(true)
          setReceiveDiscountData(res.data[0])

          const currentKey = localStorage.getItem(caKey)
          if (!currentKey) {
            setDlologVisible(true)
            buriedSdk?.buried({
              event: '显示领会员弹窗',
              scene: `满足领会员条件的用户`,
              page_name: '首页',
              page_url: document?.URL,
              user_id: getStorageInfo()?.userId,
              json_str: JSON.stringify({
                marketing_id: res.data[0]?.marketingId
              }),
              URL: process.env.NEXT_PUBLIC_USER_API_DOMAIN
            })
          }

          getTipVisible() // 提示
        } else {
          setGiftVisible(false)
        }
        return
      }
      setGiftVisible(false)
    })
  }
  useEffect(() => {
    getPayDiscountData()
    return () => {
      setTipVisible(false)
      setDlologVisible(false)
    }
  }, [])

  const handleDialogClose = () => {
    setDlologVisible(false)
    localStorage.setItem(caKey, nowDate)
    getTipVisible()
  }
  const handleClick = (e) => {
    e.stopPropagation()
    buriedSdk?.buried({
      event: '点击事件',
      scene: `领取会员按钮-活动入口统计`,
      page_name: '首页',
      page_url: document?.URL,
      user_id: getStorageInfo()?.userId,
      json_str: JSON.stringify({
        marketing_id: receiveDiscountData?.marketing_id
      }),
      URL: process.env.NEXT_PUBLIC_USER_API_DOMAIN
    })
    setDlologVisible(true)
  }

  const pushMsg = (msg) => {
    setErrMsg(msg)
    setTimeout(() => {
      setErrMsg('')
    }, 3000)
  }

  const handleRecevieSuc = () => {
    setDlologVisible(false)
    getPayDiscountData()
    pushMsg('Claim success')
  }

  const handleRecevieError = (msg) => {
    setDlologVisible(false)
    pushMsg(msg)
  }
  return (
    <>
      {giftVisible ? (
        <div>
          <div className="relative">
            <div
              className={`${styles['gift-img']}  cursor-pointer ${
                isHome ? 'right-[110px]' : 'right-[66px]'
              }`}
              onClick={handleClick}
            >
              <Image
                width={30}
                height={30}
                src="https://mcdn.bastaccount.net/custom/images/activity/receiveActivity/gift.png"
                alt="gift-img"
              />
            </div>
            <div
              className={`${styles['tip-top']} ${isHome ? 'right-[110px]' : 'right-[66px]'} ${
                !tipVisible ? 'hidden' : ''
              }`}
            >
              <div className="flex justify-between items-center">
                <span className={`${styles['text']}`}>Get Free Vip</span>
              </div>
            </div>
          </div>
          {dlologVisible && (
            <GiftDialog
              receiveDiscountData={receiveDiscountData}
              onClose={handleDialogClose}
              onSuc={handleRecevieSuc}
              onError={handleRecevieError}
            />
          )}
        </div>
      ) : null}
      <Toast msg={errMsg} />
    </>
  )
}

export default Gift
