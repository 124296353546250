/*eslint-disable*/
import CryptoJS from 'crypto-js'
import { IncomingMessage } from 'http'
import { sha256 } from 'js-sha256'
import { GetServerSidePropsContext, NextApiRequest, PreviewData } from 'next'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { ParsedUrlQuery } from 'querystring'
import { getCookie, setCookie, deleteCookie, getCookies } from 'cookies-next'
import { hyphenate } from './format'
import { getRequestMeta } from 'next/dist/server/request-meta'

interface BuriedHeaders {
  referer?: string | undefined //当前链接
  sessionId?: string | undefined //sessionId
  systemId?: number | undefined
  deviceId?: string | undefined
  xForwardedFor?: string | undefined
  sourceUrl?: string | undefined //前一个链接
  userAgent?: string | string[] | undefined
}

type requestType = IncomingMessage & {
  cookies: NextApiRequestCookies
}

function a(e: any) {
  var t = CryptoJS.AES.encrypt(e, 'TheFuckYouLookingForYouPieceOfShit').toString()
  return Buffer.from(t).toString('hex')
}

const mayhem = a(String(Math.floor(0x31566ad169c01c000 * Math.random()) + 0)).toString()

let time = Date.now()

const t = {
  'x-midas': (function (e) {
    return sha256.update(e)['hex']()
  })(
    '0AVwElhWi1IfwcZKSNzq7E^84hFQ4ykenNAxeY7r@6ho1oTd6Ug*!WC&p$2aGY8MLHEkH0i8XCwnj3#JqI1NzCb91$gNzLYCbbG' +
      '@NqvQMbcf8W9v3%s#uzjP@z*!e9a41JNWBqRIMJ*ULuav5k8z4kBj2^BCC%!3q@N0zZOS^TL#GzVz@9fhjg&^mSWi&oU5GMoCu9' +
      time
  ),
  'x-joey': time
}

export function getHead() {
  return {
    Origin: 'https://tokcount.com',
    Referer: 'https://tokcount.com',
    'x-aurora': (3 * time).toString(),
    'x-maven': t['x-midas'],
    'x-joey': t['x-joey'],
    'x-mayhem': mayhem,
    'x-midas': (function (e) {
      return sha256.update(e)['hex']()
    })((time + 64).toString())
  }
}

//服务器渲染拿到埋点头部
export function getBureidHeadersFromServerContext(
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
) {
  // if (!context) return {};
  if (process.browser || !context) return {}
  // debugger;
  const { req, res } = context
  const hasDeviceId = !!req.cookies.BURIEDSDK_DEVICEID
  const hasSessionId = !!req.cookies.BURIEDSDK_EXPIRES
  // console.log("99999", req?.headers, req?.headers?.referer);
  const BURIEDSDK_EXPIRES = req.cookies.BURIEDSDK_EXPIRES || algorithm()
  const BURIEDSDK_DEVICEID = req.cookies.BURIEDSDK_DEVICEID || algorithm()

  // res.setHeader(
  //     "set-cookie",
  //     (req.headers.cookie || "") +
  //         fixedBuriedInit(
  //             hasDeviceId,
  //             hasSessionId,
  //             BURIEDSDK_EXPIRES,
  //             BURIEDSDK_DEVICEID
  //         )
  // );

  if (!hasDeviceId) toSaveDeviceId(context, BURIEDSDK_DEVICEID)
  if (!hasSessionId) toSaveEXOURES(context, BURIEDSDK_EXPIRES)
  // hyphenate();

  // const NEXT_REQUEST_META = Symbol("NextRequestMeta");

  let realUrl = ''
  // if (req?.url === "/_next/data/development/zh.json") realUrl = "/zh";
  // if (req?.url === "/_next/data/development/zh.json") realUrl = "/";
  // console.log("~~~~~~~~~", req?.headers, realUrl);
  const params = {
    referer: fixedHttp(realUrl || req?.url || ''), //当前链接
    sessionId: BURIEDSDK_EXPIRES, //sessionId
    systemId: 14,
    deviceId: BURIEDSDK_DEVICEID,
    xUser: getServerToken(req.cookies),
    xForwardedForBastaccount:
      getXforwardedForIp(req) || req?.connection?.remoteAddress || req?.socket?.remoteAddress,
    sourceUrl: fixedHttp(req?.headers?.referer || realUrl || req?.url || ''),
    userAgent: req?.headers['user-agent'] || ''
  }
  if (getServerToken(req.cookies)) {
    params['xUser'] = getServerToken(req.cookies)
  }
  /**eslint disabled */
  return Object.keys(params).reduce((current, key) => {
    const rawKey = key
    current[hyphenate(key)] = params[rawKey]
    return current
  }, {})
  // return params;
}

const getXforwardedForIp = (req: requestType) => {
  const xForwardedFor = (req.headers['x-forwarded-for'] || '') as string
  return xForwardedFor
}

// 获取唯一数
/*eslint-disable*/
export function algorithm() {
  let abc = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'g',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
  ]
  let [max, min] = [
    Math.floor(Math.random() * (10 - 7 + 1) + 1),
    Math.floor(Math.random() * (17 - 10 + 1) + 17)
  ]
  const abcn = abc
    .sort(() => 0.4 - Math.random())
    .slice(max, min)
    .slice(0, 19)
    .join('') as string
  var a = Math.floor(new Date().getTime() * Math.random() * 100) + abcn
  return a
}

function toSaveDeviceId(context: any, id: string) {
  setCookie('BURIEDSDK_DEVICEID', id, {
    path: '/',
    domain: '.bastaccount.com',
    req: context.req,
    res: context.res
  })
  // throw new Error("Function not implemented.");
}
function toSaveEXOURES(context: any, id: string) {
  setCookie('BURIEDSDK_EXPIRES', id, {
    path: '/',
    domain: '.bastaccount.com',
    req: context.req,
    res: context.res
  })
  // throw new Error("Function not implemented.");
}
//如果没有http加上公共链接
function fixedHttp(url: string) {
    if (!url) return process.env.publicUrl;
    if (url.indexOf("http") > -1) return url;
    return process.env.publicUrl + url;
}

function getServerToken(cookies: any) {
  if (!cookies) return ''
  if (!cookies['authorized-token']) return ''
  const auth = JSON.parse(cookies['authorized-token'])
  return (auth.userId || '') + ',' + (/^[u4e00-u9fa5]{0,}$/g.test(auth.name ) ? auth.name  : encodeURI(auth.name )|| '')
}
