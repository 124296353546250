import { useTranslation } from 'next-i18next'
import Switcher from '@@/local-switcher/index'
import styles from './index.module.scss'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { LegacyRef, useEffect, useRef, useState } from 'react'
import Logo from '@/public/images/logo_1@2x.png'
import Image from 'next/image'
// import UserPanel from "@@/user-panel/index";
import { useWidth } from '@/utils/resize'
import googleOneTap from '@/utils/googleOneTab.js'
import { googleOneLogin, googleClientId } from '@/service'
import Channel from '@/utils/channel'
import InviteCode from '@/utils/inviteCode'
import { setToken } from '@/utils/auth'
import adLogo from '@images/adwinsLogo.png'
import ChromeLogo from '@images/chrome@2x.png'
import newGif from '@images/new.gif'
import Node from '@/utils/selector'
import { getStorageInfo } from '@/utils/auth'
import MenuList from './components/MenuList'
import MobileMenuList from './components/MobileMenuList'
// import orange from "@/utils/lucky-orange";
import dynamic from 'next/dynamic'
import { trackEventFn } from '@/utils/gtag'
import hot from '@images/home/hot.gif'
import { useMenu } from './components/menu'
import Questionnaire from './components/Questionnaire'
import buriedSdk from '@/utils/zz-buried/index'
const UserPanel = dynamic(import('@@/user-panel/index'))
import { setCookie, getCookie } from 'cookies-next'
import { getLangLink } from '@/utils/lang'
import Gift from './components/Gift/index'
import TabbarTip from './components/TabbarTip/index'
// const Switcher = dynamic(import("@@/local-switcher/index"));

const Nav = (props: any) => {
  const { isHome } = props
  const [show, setShow] = useState(false)
  const { t, i18n } = useTranslation('nav')
  const router = useRouter()
  const { width, display } = useWidth()
  const [isOpen, setIsOpen] = useState(false)
  const mobileUrl = process.env.mobileUrl
  const pcUrl = process.env.portalUrl
  const { locales, locale: activeLocale } = router
  const menu = useMenu(activeLocale)
  const [curLang, setCurLang] = useState('en')
  const [mShowContry, setMShowContry] = useState(false)
  const handleMobileClose = () => {
    if (typeof window !== 'undefined') {
      const nav = new Node('.mobile-nav').el
      const modal = new Node('.mobileModal').el
      const mask = new Node('.modal-mask')
      nav?.classList.remove('mobile-nav-in')
      nav?.classList.add('mobile-nav-out')
      mask.remove()

      if (modal) modal.style.display = 'none'
      setTimeout(() => {
        new Node('.nav').el?.classList.remove('mobile')
        document.body.style.overflow = 'auto'
        setIsOpen(false)
      }, 400)
    }
  }
  const handleMobileOpen = () => {
    if (typeof window !== 'undefined') {
      setIsOpen(true)
      setTimeout(() => {
        const nav = new Node('.mobile-nav').el
        const modal = new Node('.mobileModal').el
        if (nav) nav.style.display = 'block'
        nav?.classList.remove('mobile-nav-out')
        nav?.classList.add('mobile-nav-in')
        if (modal) modal.style.display = 'block'
        new Node('.nav').el?.classList.add('mobile')
        const div = document.createElement('div')
        div.className = 'modal-mask'
        document.body.append(div)
        document.body.style.overflow = 'hidden'
        const mask = new Node('.modal-mask').el
        mask?.addEventListener('click', handleMobileClose)
        if (mask) mask.style.display = 'block'
      })
    }
  }
  const handleLangList = () => {
    setShow(!show)
  }
  const toSearch = () => {
    window.location.href = process.env.mobileUrl! + '/search'
  }
  const handleLogin = () => {
    buriedSdk.buried({
      event: `bastaccount官网_${width <= 900 ? 'H5' : 'Web'}`,
      scene: `菜单_注册登录按钮`,
      URL: process.env.publicApiDomain
    })
    window.location.href =
      width <= 900
        ? process.env.loginMobileUrl!
        : process.env.loginUrl! + '?fromPath=' + window.location.href
  }

  const handleToPortal = () => {
    window.location.href = process.env.portalUrl!
  }
  const getPortalLink = () => {
    return width > 1024 ? process.env.portalUrl + '/home' : process.env.mobileUrl
  }

  const googleOneLoginFn = async () => {
    // @ts-ignore
    // console.log("=======", process.env.publicMode);
    const data = await googleClientId()
    // console.log(data);

    const options = {
      client_id: (data?.data && data?.data?.clientId) || 'wdzFsdx',
      autoSelect: false, // optional
      cancelOnTapOutside: true, // optional
      context: 'signin' // optional
    }
    console.log(data)

    // @ts-ignore
    googleOneTap(options, async (response) => {
      // console.log(response);

      const params = {
        client_id: response.clientId,
        id_token: response.credential,
        source: 'google',
        system_id: 14,
        channel_id: Channel.getKey(),
        inviteCode: InviteCode.getKey()
      }

      // @ts-ignore
      const res = await googleOneLogin(params)
      // @ts-ignore
      if (!res.code) {
        //去除channel埋点
        Channel.remove()
        InviteCode.remove()
        setToken(res.data || Object.create(null))
        if (res.data.isRegister || res.data.is_register) {
          buriedSdk &&
            buriedSdk.buried({
              scene: '完成注册',
              event: '新用户注册',
              token: res.data?.token,
              URL: process.env.NEXT_PUBLIC_USER_API_DOMAIN
            })
        }
        trackEventFn
          .signUp({ method: 'GoogleOneTap' }, res.data.isRegister || res.data.is_register)
          .finally(() => {
            window.history.go(0)
          })
      }
    }).then((res) => {
      // @ts-ignore
      window.google.accounts.id.prompt()
    })
    //999
  }

  const urlMap = {
    home: process.env.publicUrl,
    influencer: process.env.portalUrl! + '/influencer/rising',
    tag: process.env.portalUrl! + '/material/tabs?name=tabsSoaring',
    video: process.env.portalUrl! + '/video/list?name=soaring',
    music: process.env.portalUrl! + '/material/music?name=musicSoaring',
    // ads: process.env.publicAds,
    ads: 'https://www.adwins.net/?channel_id=633&utm_source=Tikmeta&utm_campaign=&utm_content=&utm_medium=Tikmeta&utm_term= ',
    monitor: process.env.portalUrl! + '/toolkit/monitor',
    productVideo: process.env.portalUrl! + '/goods/goodsList',
    products: process.env.mobileUrl! + '/list?name=E-commerce&subname=Products',
    productsVideo: process.env.mobileUrl! + '/list?name=E-commerce&subname=ProductVideos',
    mobileSearch: process.env.mobileUrl! + '/discovery?type=influencers'
  }
  const handleToLink = (key: keyof typeof urlMap) => {
    window.location.href = urlMap[key] || ''
  }
  const getLink = (key: keyof typeof urlMap) => {
    return urlMap[key] || ''
  }

  // function getCookie(cname: string) {
  //     var name = cname + "=";
  //     var ca = document.cookie.split(";");
  //     for (var i = 0; i < ca.length; i++) {
  //         var c = ca[i].trim();
  //         if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  //     }
  //     return "";
  // }
  // 设置多语言到缓存
  const setLang = (language) => {
    const temp = window.location.host.split('.').reverse()
    const root_domain = '.' + temp[1] + '.' + temp[0]
    if (process.env.NODE_ENV !== 'development') {
      setCookie('responsive-locale', language, {
        domain: root_domain
      })
    } else {
      setCookie('responsive-locale', language)
    }
  }
  const selectContry = (val, platform) => {
    if (val !== curLang) {
      buriedSdk.buried({
        event: `bastaccount官网_${platform}`,
        scene: `菜单_切换语言_${val}`,
        URL: process.env.publicApiDomain
      })
      setLang(val)
      setTimeout(() => {
        let path = window.location.pathname.replace('/vi', '').replace('/id', '').replace('/th', '')
        window.location.pathname = `${val === 'en' ? '' : `/${val}`}${path}${
          window.location.search
        }`
      }, 600)
    }
  }
  const contryList = [
    { name: 'English（US）', key: 'en' },
    { name: 'Tiếng Việt', key: 'vi' },
    { name: 'ภาษาไทย', key: 'th' },
    { name: 'Bahasa Indonesia', key: 'id' }
  ]
  const contryKey = {
    en: 'English（US）',
    th: 'ภาษาไทย',
    vi: 'Tiếng Việt',
    id: 'Bahasa Indonesia'
  }
  const [isAuthorized, setAuthorized] = useState(false)
  const [userName, setUserName] = useState()
  const getUrlLang = () => {
    let str = window.location.pathname
    if (str.startsWith('/vi')) {
      return 'vi'
    } else if (str.startsWith('/id')) {
      return 'id'
    } else if (str.startsWith('/th')){
      return 'th'
    } else {
      return 'en'
    }
  }
  useEffect(() => {
  
  }, [])

  const navRef = useRef<HTMLDivElement>(null)

  const onScroll = (e: Event) => {
    if (typeof window !== 'undefined') {
      const window = e.currentTarget as Window
      if (!window.scrollY) {
        navRef.current?.classList.remove('nav-scroll')
      } else {
        navRef.current?.classList.add('nav-scroll')
      }
    }
  }
  useEffect(() => {
    //解决滑动后，刷新层级不一致
    if (window.scrollY) {
      navRef.current?.classList.add('nav-scroll')
    }
  }, [])

  useEffect(() => {
    if (router.pathname.includes('posts/[slug]/[detail]') || router.pathname.indexOf('[slug]') === -1) {
      window.addEventListener('scroll', onScroll)
      return () => {
        window.removeEventListener('scroll', onScroll)
      }
    } else {
      navRef.current?.classList.add('nav-scroll')
    }
  }, [])

  return (
    <>
      <div className={`${styles.nav} nav nav-fixed-screen-width:min-w-[993Px]`} ref={navRef}>
        <div
          className={
            'defeaultContainer mx-[auto] h-[70Px] flex justify-between ' + styles['nav-wrapper']
          }
        >
          <div className={styles.left}>
            {isHome && width < 1024 ? (
              <li className="mr-[12px] minlg1024:hidden">
                <span className={styles.mobileOpen} onClick={handleMobileOpen}>
                  <i className="iconfont icon-a-47caidan !text-[24px]"></i>
                </span>
              </li>
            ) : null}
            <a
              href={getLink('home') + getLangLink('', activeLocale)}
              className={styles.logo + ' md:!w-[100Px] md:!flex md:!items-center'}
            >
              <Image
                lazyBoundary="10px"
                alt="bastaccount | TikTok Analytics &amp; Statistics &amp; Track, Influencer Marketing and Product Selection Platform"
                src={Logo}
              ></Image>
            </a>
            {width > 1024 ? <MenuList navRef={navRef}></MenuList> : null}
          </div>
          <div className={styles.right}>
            <li className={`${styles.login}  ${isAuthorized ? 'hidden' : ''} minlg1024:!border-0`}>
              {/* <a
                                onClick={handleLogin}
                                target={"_self"}
                                rel="noreferrer"
                                className="lg1024:hidden whitespace-nowrap  md:!font-[600] md:!pd-[10Px]"
                            >
                                {`${t('Register')} / ${t('Login')}`}
                            </a> */}
              <a
                onClick={handleLogin}
                target={'_self'}
                rel="noreferrer"
                className="minlg1024:hidden whitespace-nowrap  md:!font-[600] md:!pd-[10Px]"
              >
                {`${t('Login')}`}
              </a>
            </li>

            {/* {width > 1024 ? (
              <>
                <div className={styles.contrySelect + ' h-full'}>
                  <div className={styles.selectContent + ' h-full'}>
                    <Image width={20} height={20} src={`/images/${curLang}.png`} alt=""></Image>
                    <span className="mx-[8px]">{contryKey[curLang] || contryKey.en}</span>
                    <i className="iconfont icon-a-10xiala1"></i>
                  </div>
                  <div className={styles.selectList + ' rounded-[4px]'}>
                    {contryList.map((item) => {
                      return (
                        <div
                          className={styles.selectItem}
                          key={item.key}
                          onClick={() => {
                            selectContry(item.key, 'Web')
                          }}
                        >
                          <Image
                            width={20}
                            height={20}
                            src={`/images/${item.key}.png`}
                            alt={item.key}
                          ></Image>
                          <span className={styles.selectText}>{item.name}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <li className="md:!hidden relative">
                  <a
                    className={styles.start}
                    href={getPortalLink()}
                    onClick={() => {
                      buriedSdk.buried({
                        event: `bastaccount官网_Web`,
                        scene: `菜单_Free Trial`,
                        URL: process.env.publicApiDomain
                      })
                    }}
                    rel="nofollow"
                  >
                    <span>{t('Free Trial')}</span>
                    <div className="absolute -right-[10px] -top-[20px]">
                      <Image lazyBoundary="10px" src={hot} width={36} height={36} alt=""></Image>
                    </div>
                  </a>
                </li>
              </>
            ) : (
              <div
                className={styles.contrySelect}
                onClick={() => {
                  setMShowContry(!mShowContry)
                }}
              >
                <div className={styles.selectContent}>
                  <Image width={20} height={20} src={`/images/${curLang}.png`} alt=""></Image>
                  <i className="iconfont icon-a-10xiala1 ml-[4px]"></i>
                </div>
                {mShowContry ? (
                  <div className={styles.selectList}>
                    {contryList.map((item) => {
                      return (
                        <div
                          className={styles.selectItem}
                          key={item.key}
                          onClick={() => {
                            selectContry(item.key, 'H5')
                          }}
                        >
                          <Image
                            width={20}
                            height={20}
                            src={`/images/${item.key}.png`}
                            alt={item.key}
                          ></Image>
                          <span className={styles.selectText}>{item.name}</span>
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </div>
            )} */}
            {isHome && width < 1024 && isAuthorized ? (
              <li className={`flex items-center ml-[10px]`}>
                <a href={getLink('mobileSearch')}>
                  <i className="iconfont icon-a-51sousuo !text-[24px]"></i>
                </a>
              </li>
            ) : null}
            {/* <UserPanel></UserPanel> */}
            {/* {width > 1023 ? <Switcher></Switcher> : null} */}
          </div>
        </div>
      </div>
      {width < 1024 && isOpen ? (
        <>
          <div className={`${styles.mobileNav} mobile-nav`}>
            <div className={styles.mobileTop + ' flex items-center justify-between'}>
              <div>
                <a
                  href={getLink('home') + getLangLink('', activeLocale)}
                  className={styles.logo + ' md:!w-[100Px] md:!flex md:!items-center'}
                >
                  <Image
                    lazyBoundary="10px"
                    alt="bastaccount | TikTok Analytics &amp; Statistics &amp; Track, Influencer Marketing and Product Selection Platform"
                    src={Logo}
                  ></Image>
                </a>
              </div>
              <div className="flex item-center">
                <span className={styles.mobileClose} onClick={handleMobileClose}>
                  <svg
                    className="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2243"
                    width="200"
                    height="200"
                  >
                    <path
                      d="M283.456 223.338667l2.709333 2.496L512 451.626667l225.834667-225.813334a42.666667 42.666667 0 0 1 62.826666 57.621334l-2.496 2.709333L572.373333 512l225.813334 225.834667a42.666667 42.666667 0 0 1-57.621334 62.826666l-2.709333-2.496L512 572.373333 286.165333 798.165333a42.666667 42.666667 0 0 1-62.826666-57.621333l2.496-2.709333L451.626667 512 225.834667 286.165333a42.666667 42.666667 0 0 1 57.621333-62.826666z"
                      p-id="2244"
                      fill="#2E384E"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
            <MobileMenuList
              menu={menu.filter((m) => !m?.mVisible)}
              handleMobileClose={handleMobileClose}
            ></MobileMenuList>
            {/* {isHome ? (
              <div className="flex items-center justify-center pt-[45px] border-t-[#E6E6E6] border-t-[1px]">
                <a
                  className="w-[160px] h-[40px] border-[1px] border-[#0E1527] flex items-center justify-center rounded-[24px]"
                  href={getLangLink('/', activeLocale)}
                >
                  <i className="iconfont icon-a-21zuojiantou !text-[20px] mr-[12px]  text-[#0A122C]"></i>
                  <span className="text-[14px] text-[#0A122C] font-[500]">{t('Homepage')}</span>
                </a>
              </div>
            ) : null} */}
          </div>

          <div
            className="fixed w-[15vw] top-0 right-0 h-[100vh] mobileModal"
            onClick={handleMobileClose}
          ></div>
        </>
      ) : null}
      <Questionnaire></Questionnaire>
      {width < 1024 && isHome && isAuthorized ? <Gift isHome={isHome} /> : null}
      {/* {width < 1024 && isHome && isAuthorized ? (
        <TabbarTip userComboInfo={props.userComboInfo} />
      ) : null} */}
    </>
  )
}

export default Nav
